import { useState, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import rocketImg from "../../assets/images/rocket.png";
import fusionX from "../../assets/images/FusionX.png";
import agni from "../../assets/images/Agni.png";
import swapsicle from "../../assets/images/Swapsicle.png";
import aperture from "../../assets/images/ApertureSwap.png";
import uniswap from "../../assets/images/Uniswap.png";
import glowswap from "../../assets/images/glowswap_01.png";
import proxyswap from "../../assets/images/proxyswap_01.png";
import corex from "../../assets/images/Corex_1.png";
import base from "../../assets/images/Base logo grey (1).png";

interface NetworkOption {
  label: string;
  value: string;
  src: string;
}

function DexDetails() {
  const { chainName, exchangeName } = useParams();
  const navigate = useNavigate();

  const [networkOptions] = useState<NetworkOption[]>([
    { label: "Mantle", value: "mantle", src: "img/filter/mantleFilter.svg" },
    // { label: "Base", value: "base", src: "img/filter/base.svg" },
    { label: "Telos", value: "telos", src: "img/filter/telosL.svg" },
    {
      label: "Manta Network",
      value: "manta-pacific",
      src: "img/filter/mantaFilter.svg",
    },
    { label: "Arbitrum", value: "arbitrum", src: "img/filter/arbitrum.svg" },
    { label: "B-squared", value: "b-squared", src: "img/filter/b201.svg" },
    { label: "Degen L3", value: "degen", src: "img/filter/degen01.svg" },
    {
      label: "PolygonPos",
      value: "polygonPoS",
      src: "img/filter/polygonPoS.png",
    },
    { label: "Coredao", value: "coredao", src: "img/filter/coredao_1.png" },
    { label: "Base", value: "base", src: "img/filter/black icon.png" },
  ]);
  const [network, setNetwork] = useState(chainName);
  const [dex, setDex] = useState<string>(rocketImg);
  const exchangeImageMap: { [key: string]: string } = {
    FusionX: fusionX,
    Agni: agni,
    ApertureSwap: aperture,
    Swapsicle: swapsicle,
    Uniswap: uniswap,
    GlowSwap: glowswap,
    ProxySwap: proxyswap,
    Corex: corex,
    Base: base,
  };

  useEffect(() => {
    if (exchangeName && exchangeImageMap[exchangeName]) {
      setDex(exchangeImageMap[exchangeName]);
    } else {
      setDex(rocketImg);
    }
  }, [exchangeName, exchangeImageMap]);

  const loadData = (e: string) => {
    setNetwork(e);
    navigate(e);
  };
  console.log("network", network);
  const RadioInput = ({ label, value, src }: NetworkOption) => {
    return (
      <div className="radioSectionChild">
        <input
          type="radio"
          checked={network === value}
          onChange={() => loadData(value)}
          id={value}
        />
        <label className="radioButtonLevel" htmlFor={value}>
          <img src={src} alt="" className="tvlChainFilter" />
          <span className="fnt_wgt_600 redHatFont">{label}</span>
        </label>
      </div>
    );
  };

  return (
    <div className="custom-container">
      <div className="second_section row mt-5 mb-5">
        <div className="flex align-items-center justify-content">
          <div className="justify-content-center">
            <section className="radioSection">
              {networkOptions
                .filter(
                  (option) =>
                    option.value === network ||
                    (network === "arbitrum" && option.value === "polygonPoS") ||
                    (network === "arbitrum" && option.value === "base") ||
                    (network === "polygonPoS" && option.value === "arbitrum") ||
                    (network === "polygonPoS" && option.value === "base") ||
                    (network === "base" && option.value === "arbitrum") ||
                    (network === "base" && option.value === "polygonPoS")
                )
                .map((e, index) => (
                  <RadioInput
                    key={index}
                    label={e.label}
                    value={e.value}
                    src={e.src}
                  />
                ))}
            </section>
          </div>
          <div>
            <img src={dex} alt="tvl" width={200} className="dex_FullImage" />
          </div>
        </div>
        <div className="mb-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default DexDetails;
