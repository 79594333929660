import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";

import USDT from "../../assets/images/portfolio/usdt.png";
import weth from "../../assets/images/portfolio/weth.png";
import wtlos from "../../assets/images/portfolio/wtelos.png";
import usdc from "../../assets/images/portfolio/usdcLogo.svg";
import mantle from "../../assets/images/portfolio/mantle.svg";
import telos from "../../assets/images/portfolio/telosLogo.svg";
import manta from "../../assets/images/portfolio/manta.png";
import arbilogo from "../../assets/images/portfolio/arbilogo01.png";
import fusionX from "../../assets/images/portfolio/fusionXLogo.svg";
import swapsicle from "../../assets/images/portfolio/swapLogo.svg";
import ApertureSwap from "../../assets/images/portfolio/apertureDex.svg";
import agni from "../../assets/images/portfolio/agniDex.svg";
import wdegen from "../../assets/images/portfolio/degen1.png";
import wmatic from "../../assets/images/portfolio/wmatic.png";
import b2 from "../../assets/images/portfolio/b2img.png";
import poly from "../../assets/images/portfolio/polylogo.png";
import wcore from "../../assets/images/portfolio/wcore.png";
import base from "../../assets/images/portfolio/base.svg";
import "./portfolio.css";
interface ImageMap {
  [key: string]: string;
}
const imageMap: ImageMap = {
  USDT: USDT,
  WETH: weth,
  WTLOS: wtlos,
  USDC: usdc,
  WDEGEN: wdegen,
  WMATIC: wmatic,
  WCORE: wcore,
  mantle: mantle,
  telos: telos,
  manta: manta,
  Arbitrum: arbilogo,
  FusionX: fusionX,
  Swapsicle: swapsicle,
  ApertureSwap: ApertureSwap,
  Agni: agni,
  bsquared: b2,
  degen: wdegen,
  polygonPoS: poly,
  coredao: wcore,
  base: base,
};
interface DoughnutChartWithPercentageProps {
  dexFrequencyMap: Map<string, number>;
  title: string;
}

interface ChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
  }[];
}
const DoughnutChartWithPercentage: React.FC<
  DoughnutChartWithPercentageProps
> = ({ dexFrequencyMap, title }) => {
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      let dataToShow = dexFrequencyMap;

      if (dexFrequencyMap.size === 0) {
        dataToShow = new Map<string, number>();
        dataToShow.set("No Data Active Found", 0.0001);
      }
      const labels = Array.from(dataToShow.keys());
      const frequencies = Array.from(dataToShow.values());
      const backgroundColors = [
        "#914DE5",
        "#FF835E",
        "#25A477",
        "#2A88D2",
        "#494949",
        "#76D3E5",
        "#DEDEDE",
        "#ffcc00",
        "#ffffff",
      ];
      const newData: ChartData = {
        labels,
        datasets: [{ data: frequencies, backgroundColor: backgroundColors }],
      };
      const options = {
        cutout: "60%",
        plugins: {
          legend: {
            display: false,
          },
        },
      };
      setChartData(newData);
      setChartOptions(options);
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(loadingTimeout);
  }, [dexFrequencyMap]);
  const values = chartData.labels.map((label, index) => {
    let firstWord = label.split(" ")[0];
    if (
      firstWord === "Asymmetric" ||
      firstWord === "Stable" ||
      firstWord === "Steady" ||
      firstWord === "AI"
    ) {
      return {
        label: firstWord,
        frequency: chartData.datasets[0].data[index],
        color: chartData.datasets[0].backgroundColor[index],
      };
    } else {
      return {
        label: label.includes("Strategy")
          ? label.replace("Spread Strategy", "").trim()
          : label.includes("Bull")
          ? label.replace(" Asymmetric Bull", "").trim()
          : label,
        frequency: chartData.datasets[0].data[index],
        color: chartData.datasets[0].backgroundColor[index],
      };
    }
  });

  const getImagePath = (label: string) => {
    const image = imageMap[label];
    return image || "";
  };
  return (
    <div className="doughnut border2">
      <h3 className="redHatFont fontbold  mt-1">{title}</h3>
      <div className="flexDo align-items-center">
        <div className="">
          {isLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="spinner mt-5"></div>
            </div>
          )}
          {!isLoading && title !== "No Data Active Found" && (
            <Chart
              type="doughnut"
              data={chartData}
              options={chartOptions}
              className="dough_width"
            />
          )}
          {title === "No Data Active Found" && (
            <div className="connect-wallet-message">
              Connect your wallet to view data.
            </div>
          )}
        </div>
        <div className="values">
          {values.map((item, index) => (
            <div
              key={index}
              className="grid mrgn_btm_15 grid-3 gap-x-2 align-items-center value-item"
            >
              {title !== "Strategy Type" ? (
                <div style={{ position: "relative" }}>
                  {item.label !== "No Data Active Found" && (
                    <div
                      className="small_circle"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        backgroundColor: item.color,
                      }}
                    >
                      <div
                        style={{
                          width: "11px",
                          height: "11px",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </div>
                  )}
                  <div className="">
                    {item.label !== "No Data Active Found" && (
                      <img
                        src={getImagePath(item.label)}
                        alt={item.label}
                        className="w_30"
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="small_circle"
                  style={{ backgroundColor: item.color }}
                ></div>
              )}
              {item.label !== "No Data Active Found" && (
                <>
                  <p className="headingDo whitespace-nowrap capitalize-words">
                    {item.label}
                  </p>
                  <p className="font_181 ml-1">{item.frequency}%</p>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default DoughnutChartWithPercentage;
