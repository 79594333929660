import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  DataTable,
  DataTableExpandedRows,
  DataTableValueArray,
} from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import fusionx2 from "../../assets/images/portfolio/Fusionx03.png";
import swap2 from "../../assets/images/portfolio/swap02.png";
import aperture from "../../assets/images/portfolio/Aperture03.png";
import agni from "../../assets/images/portfolio/agni02.png";
import uniswap from "../../assets/images/portfolio/uniswap02.png";
import proxy1 from "../../assets/images/portfolio/proxy1.png";
import glow from "../../assets/images/portfolio/Group 375.png";
import corex from "../../assets/images/portfolio/Corex_1.png";
import axios from "axios";
import mantle02 from "../../assets/images/portfolio/Mantle03.png";
import manta01 from "../../assets/images/portfolio/manta01.png";
import arbi01 from "../../assets/images/portfolio/arbi01.png";
import telos01 from "../../assets/images/portfolio/telos01.png";
import degen from "../../assets/images/portfolio/degen01.png";
import bsq from "../../assets/images/portfolio/bsq01.png";
import poly from "../../assets/images/portfolio/polygon01.png";
import coredao from "../../assets/images/portfolio/corefull.png";
import base from "../../assets/images/portfolio/Base logo grey (1).png";

import "./portfolio.css";
interface BalanceItem {
  identifier: string;
  denominationAsset: string;
  totalShares: string;
  totalAssets: number;
  portfolioUSD: number;
}

interface VaultItem {
  displayName: string;
  chain: string | null;
  identifier: string;
  strategyType: string;
  symbol: string;
  dex: string;
  transactions: VaultTransaction[];
  totalBalance?: string;
  totalAssets?: number;
  portfolioUSD?: number;
  denominationAsset: string;
}

interface VaultTransaction {
  identifier: string;
  timestamp: string;
  transactionHash: string;
  chainRPC: string;
  vaultAddress: string;
  denominationAsset: string;
  denominationDecimal: string;
  eventName: string;
  caller: string;
  receiver: string;
  assets: number;
  shares: number;
  symbol: string;
  valueUSD: number;
}
interface MyComponentProps {
  address: string | undefined;
}

const MyComponent: React.FC<MyComponentProps> = ({ address }) => {
  const navigate = useNavigate();
  const [matchedData, setMatchedData] = useState<VaultItem[]>([]);
  const [expandedRows, setExpandedRows] = useState<
    DataTableExpandedRows | DataTableValueArray | undefined
  >(undefined);
  const [balances, setBalances] = useState<BalanceItem[]>([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [selectedChain, setSelectedChain] = useState<string | null>("All");
  const [selectedStrategyType, setSelectedStrategyType] = useState<
    string | null
  >("All");
  const [filteredData, setFilteredData] = useState<VaultItem[]>([]);
  const [vaults, setVaults] = useState<VaultItem[]>([]);
  const [loading, setLoading] = useState(true);

  function formatDate(timestamp: string): string {
    const parts = timestamp.split(",")[0].split("/");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const year = parseInt(parts[2]);
    return `${day}/${month}/${year}`;
  }
  const parseDate = (dateString: string): Date => {
    const [datePart, timePart, period] = dateString.split(/[ ,]+/);
    const [day, month, year] = datePart.split("/").map(Number);
    let [hours, minutes, seconds] = timePart.split(":").map(Number);

    if (period.toLowerCase() === "pm" && hours !== 12) {
      hours += 12;
    } else if (period.toLowerCase() === "am" && hours === 12) {
      hours = 0;
    }

    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const firstResponse = await fetch(
          `https://api.rivera.money/portfolio/${address}`
        );
        const firstData = await firstResponse.json();
        const balancesFromAPI: BalanceItem[] = firstData.portfolio;

        const secondResponse = await fetch("https://api.rivera.money/vaults");

        const bigData: any = await secondResponse.json();
        const secondData = bigData.map((vault: VaultItem) => {
          return {
            identifier: vault.identifier,
            displayName: vault.displayName,
            dex: vault.dex,
            chain: vault.chain,
            strategyType: vault.strategyType,
            denominationAsset: vault.denominationAsset,
          };
        });

        const updatedVaults: VaultItem[] = [];

        const fetchPromises = secondData.map(async (vault: VaultItem) => {
          const vaultIdentifier = vault.identifier;

          try {
            const transactionResponse = await axios.get(
              `https://api.rivera.money/portfolio/${address}/${vaultIdentifier}`
            );
            let transactionData: VaultTransaction[] = transactionResponse.data;
            const matchedBalance = balancesFromAPI.find(
              (balance) => balance.identifier === vault.identifier
            );
            const totalAssets = matchedBalance ? matchedBalance.totalAssets : 0;
            const portfolioUSD = matchedBalance
              ? matchedBalance.portfolioUSD
              : 0;
            transactionData = transactionData.sort(
              (a, b) =>
                parseDate(b.timestamp).getTime() -
                parseDate(a.timestamp).getTime()
            );
            if (transactionData.length > 0) {
              const updatedVault: VaultItem = {
                ...vault,
                transactions: transactionData,
                totalAssets: totalAssets,
                portfolioUSD: portfolioUSD,
              };
              updatedVaults.push(updatedVault);
            }
          } catch (error) {
            console.error(
              `Error fetching transactions for vault ${vaultIdentifier}:`,
              error
            );
          }
        });

        await Promise.all(fetchPromises);
        setBalances(balancesFromAPI);
        setVaults(updatedVaults);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);
  const expandAll = () => {
    let _expandedRows: DataTableExpandedRows = {};
    matchedData.forEach((p) => (_expandedRows[`${p.identifier}`] = true));
    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(undefined);
  };
  const onPageChange = (event: { first: number; rows: number }) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  // Chain and strategy type options
  const chainOptions = [
    "All",
    "Mantle",
    "Telos",
    "Manta-Pacific",
    "Arbitrum",
    "Degen",
    "B-squared",
    "PolygonPoS",
    "Coredao",
    "Base",
  ];
  const strategyTypeOptions = [
    "All",
    "Steady Spread Strategy",
    "Stable Spread Strategy",
    "Asymmetric Bull Strategy",
    "AI Powered Strategy",
  ];

  useEffect(() => {
    const filteredData = vaults.filter((vault: VaultItem) => {
      return (
        (selectedChain?.toLowerCase() === "all" ||
          (vault.chain?.toLowerCase() ?? "") ===
            selectedChain?.toLowerCase()) &&
        (selectedStrategyType === "All" ||
          vault.strategyType === selectedStrategyType)
      );
    });
    setFilteredData(filteredData);
  }, [selectedChain, selectedStrategyType, vaults]);

  const allowExpansion = (rowData: VaultItem) => {
    return rowData?.transactions?.length > 0;
  };

  const rowExpansionTemplate = (data: VaultItem) => {
    return (
      <div className="">
        <div className="">
          <DataTable className="" value={data.transactions}>
            <Column
              field="eventName"
              header="Activity"
              className="redHatFont fontbold capitalize-words redHatFont droptable fontbold whitespace-nowrap header_of_table_exp"
            />
            <Column
              field="timestamp"
              header="Date"
              body={(rowData) => formatDate(rowData.timestamp)}
              className="redHatFont fontbold capitalize-words redHatFont droptable fontbold whitespace-nowrap header_of_table_exp"
            />
            <Column
              field="assets"
              header="Assets"
              body={(rowData) =>
                `${Number(rowData.assets.toFixed(4))} ${
                  rowData.denominationAsset
                }`
              }
              className="redHatFont fontbold capitalize-words redHatFont droptable fontbold whitespace-nowrap header_of_table_exp"
            />
            <Column
              field="valueUSD"
              header="Volume"
              body={(rowData) => `$ ${Number(rowData.valueUSD.toFixed(2))}`}
              className="redHatFont fontbold capitalize-words redHatFont droptable fontbold whitespace-nowrap header_of_table_exp"
            />
          </DataTable>
        </div>
      </div>
    );
  };
  const imageBodyTemplate = (rowData: VaultItem) => {
    if (rowData.dex === "Swapsicle") {
      return (
        <img src={swap2} alt={rowData.dex} width="100px" className="shadow-4" />
      );
    } else if (rowData.dex === "ApertureSwap") {
      return (
        <img
          src={aperture}
          alt={rowData.dex}
          width="100px"
          className="shadow-4"
        />
      );
    } else if (rowData.dex === "FusionX") {
      return (
        <img
          src={fusionx2}
          alt={rowData.dex}
          width="100px"
          className="shadow-4"
        />
      );
    } else if (rowData.dex === "Agni") {
      return (
        <img src={agni} alt={rowData.dex} width="100px" className="shadow-4" />
      );
    } else if (rowData.dex === "Uniswap") {
      return (
        <img
          src={uniswap}
          alt={rowData.dex}
          width="100px"
          className="shadow-4"
        />
      );
    } else if (rowData.dex === "ProxySwap") {
      return (
        <img
          src={proxy1}
          alt={rowData.dex}
          width="100px"
          className="shadow-4"
        />
      );
    } else if (rowData.dex === "GlowSwap") {
      return (
        <img src={glow} alt={rowData.dex} width="100px" className="shadow-4" />
      );
    } else if (rowData.dex === "Corex") {
      return (
        <img src={corex} alt={rowData.dex} width="100px" className="shadow-4" />
      );
    } else {
      return <img alt={rowData.dex} width="100px" className="shadow-4" />;
    }
  };
  const imageBodyTemplate2 = (rowData: VaultItem) => {
    if (rowData.chain === "mantle") {
      return (
        <img
          src={mantle02}
          alt={rowData.chain}
          width="100px"
          className="shadow-4"
        />
      );
    } else if (rowData.chain === "manta-pacific") {
      return (
        <img
          src={manta01}
          alt={rowData.chain}
          width="100px"
          className="shadow-4"
        />
      );
    } else if (rowData.chain === "telos") {
      return (
        <img
          src={telos01}
          alt={rowData.chain}
          width="64px"
          className="shadow-4"
        />
      );
    } else if (rowData.chain === "arbitrum") {
      return (
        <img
          src={arbi01}
          alt={rowData.chain}
          width="120px"
          className="shadow-4"
        />
      );
    } else if (rowData.chain === "degen") {
      return (
        <img
          src={degen}
          alt={rowData.chain}
          width="100px"
          className="shadow-4"
        />
      );
    } else if (rowData.chain === "b-squared") {
      return (
        <img src={bsq} alt={rowData.chain} width="120px" className="shadow-4" />
      );
    } else if (rowData.chain === "polygonPoS") {
      return (
        <img
          src={poly}
          alt={rowData.chain}
          width="120px"
          className="shadow-4"
        />
      );
    } else if (rowData.chain === "coredao") {
      return (
        <img
          src={coredao}
          alt={rowData.chain}
          width="120px"
          className="shadow-4"
        />
      );
    } else if (rowData.chain === "base") {
      return (
        <img
          src={base}
          alt={rowData.chain}
          width="120px"
          className="shadow-4"
        />
      );
    } else {
    }
  };

  return (
    <>
      <div>
        <div className="frame table-label ">
          <h3 className="transaction-history2 redHatFont fontbold">Vaults</h3>
          <div className="flexopt redHatFont">
            <label className="fontbold flexnone redHatFont">
              Chain
              <Dropdown
                className=" dropDown redHatFont imDropwdth"
                value={selectedChain}
                options={chainOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                onChange={(e) => setSelectedChain(e.value)}
                placeholder="Select Chain"
              />
            </label>
            <label className="fontbold flexnone redHatFont">
              Strategy
              <Dropdown
                className=" dropDown redHatFont imDropwdth"
                value={selectedStrategyType}
                options={strategyTypeOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                onChange={(e) => setSelectedStrategyType(e.value)}
                placeholder="Select Strategy Type"
              />
            </label>
          </div>
        </div>

        {loading ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="spinner"></div>
            </div>
          </>
        ) : (
          <DataTable
            className="table-containe1 exp-table border2Table redHatFont  "
            value={filteredData}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            paginator
            paginatorClassName="imDark"
            rows={rows}
            first={first}
            onPage={onPageChange}
            onRowClick={(e) => navigate(`/vault/${e.data.identifier}`)}
            rowClassName={() => "clickable-row"}
          >
            <Column
              field="displayName"
              header="Vault"
              className=" redHatFont whitespace-nowrap fontbold capitalize-words exp-table redHatFont droptable header_of_table_exp"
            />
            <Column
              field="strategyType"
              header="Strategy"
              className="redHatFont fontbold capitalize-words redHatFont droptable fontbold whitespace-nowrap header_of_table_exp"
            />
            <Column
              field="dex"
              header="DEX"
              body={imageBodyTemplate}
              className="redHatFont fontbold capitalize-words redHatFont droptable fontbold whitespace-nowrap header_of_table_exp"
            />
            <Column
              field="chain"
              header="Chain"
              body={imageBodyTemplate2}
              className="redHatFont fontbold capitalize-words redHatFont droptable fontbold whitespace-nowrap header_of_table_exp"
            />
            <Column
              field="totalAssets"
              header="Vault Balance"
              body={(rowData) =>
                `${Number(rowData.totalAssets.toFixed(4))} ${
                  rowData.denominationAsset
                }`
              }
              className="redHatFont fontbold capitalize-words redHatFont droptable fontbold whitespace-nowrap header_of_table_exp"
            />
            <Column
              field="portfolioUSD"
              header="Portfolio "
              body={(rowData) =>
                `$  ${Number(rowData.portfolioUSD.toFixed(2))}`
              }
              className="redHatFont fontbold capitalize-words redHatFont droptable fontbold whitespace-nowrap header_of_table_exp"
            />
            <Column
              expander={allowExpansion}
              className="redHatFont fontbold redHatFont droptable fontbold whitespace-nowrap header_of_table_exp"
            />
          </DataTable>
        )}
      </div>
      {/* )} */}
    </>
  );
};

export default MyComponent;
