import StablePairColorImg from "../../assets/images/StablePairColor.svg";
import VolatilePairColorImg from "../../assets/images/volatilePair.png";
import dynamicStrategyImg from "../../assets/images/dynamicStrategy.png";
import aipowered from "../../assets/images/Group 1124.svg";
import almImg from "../../assets/images/alm.svg";
import trend from "../../assets/images/Trending.png";
import boosted from "../../assets/images/Boosted 2.svg";
import deprecated from "../../assets/images/deprecated.svg";
import farming from "../../assets/images/farming.svg";
import LSDFarmingImg from "../../assets/images/LSDFarming.svg";
import revenueEarningImg from "../../assets/images/revenueEarning.svg";
export const getTwoDecimal = (num: any) => {
  const roundedNum = Math.floor(num * 100) / 100;
  return roundedNum.toFixed(2);
};
export const getFormatedData = (x: any) => {
  if (isNaN(x)) return x;

  if (x < 999) {
    return getTwoDecimal(x);
  }

  if (x < 1000) {
    return getTwoDecimal(x);
  }

  if (x < 10000) {
    return getTwoDecimal(x / 1000) + "K";
  }

  if (x < 100000) {
    return getTwoDecimal(x / 1000) + "K";
  }

  if (x < 1000000) {
    return getTwoDecimal(x / 1000) + "K";
  }

  if (x < 10000000) {
    return getTwoDecimal(x / 1000000) + "M";
  }

  if (x < 1000000000) {
    return getTwoDecimal(x / 1000000) + "M";
  }

  if (x < 1000000000000) {
    return getTwoDecimal(x / 1000000000) + "B";
  }

  return "1T+";
};

export const getFormated = (x: any) => {
  if (isNaN(x)) return x;

  if (x < 999) {
    return getTwoDecimal(x);
  }

  if (x < 1000) {
    return getTwoDecimal(x);
  }

  if (x < 10000) {
    return getTwoDecimal(x / 1000) + "K";
  }

  if (x < 100000) {
    return getTwoDecimal(x / 1000) + "K";
  }

  if (x < 1000000) {
    return getTwoDecimal(x / 1000) + "K";
  }

  if (x < 10000000) {
    return getTwoDecimal(x / 1000000) + "M";
  }

  if (x < 1000000000) {
    return getTwoDecimal(x / 1000000) + "M";
  }

  if (x < 1000000000000) {
    return getTwoDecimal(x / 1000000000) + "B";
  }

  return "1T+";
};

export const fnum = (x: any) => {
  if (isNaN(x)) return x;

  if (x < 999) {
    return getTwoDecimal(x);
  }

  if (x < 1000) {
    return getTwoDecimal(x);
  }

  if (x < 10000) {
    return getTwoDecimal(x / 1000) + "K";
  }

  if (x < 100000) {
    return getTwoDecimal(x / 1000) + "K";
  }

  if (x < 1000000) {
    return getTwoDecimal(x / 1000) + "K";
  }
  if (x < 10000000) {
    return getTwoDecimal(x / 1000000) + "M";
  }

  if (x < 1000000000) {
    return getTwoDecimal(x / 1000000) + "M";
  }

  if (x < 1000000000000) {
    return getTwoDecimal(x / 1000000000) + "B";
  }

  return "1T+";
};

export const getTagIcon = (tag: string): string => {
  switch (tag) {
    case "AI Powered":
      return aipowered;
    case "Uptrend Market":
      return trend;
    case "Active Strategy":
      return almImg;
    case "Revenue Earning":
      return revenueEarningImg;
    case "Wide Range":
      return almImg;
    case "Pegged Strategy":
      return dynamicStrategyImg;
    case "Static Range":
      return dynamicStrategyImg;
    case "Dynamic":
      return dynamicStrategyImg;
    case "LSD Farming":
      return LSDFarmingImg;
    case "Volatile Pair":
      return VolatilePairColorImg;
    case "Stable Pair":
      return StablePairColorImg;
    case "Boosted":
      return boosted;
    case "Deprecated":
      return deprecated;
    case "Farming":
      return farming;

    default:
      return almImg;
  }
};
