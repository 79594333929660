import "../../App.css";
import { Menubar } from "primereact/menubar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import mobileLogoImg from "../../assets/images/logoHeader.svg";
import { ReactComponent as Sun } from "../../assets/images/Sun.svg";
import { ReactComponent as Moon } from "../../assets/images/Moon.svg";
import { useEffect, useState } from "react";
import ConnectBtn from "../../component/ConnectBtn";
import DexDrop from "../../component/DexDrop";
import Rewards from "../../component/Rewardsbtn";
import Banner from "./Banner";
import HeadlessSidebar from "./Sidebar";

function RoutingLayout() {
  const location = useLocation();
  const { pathname } = location;
  const [checkBoxVal, setCheckBoxVal] = useState<boolean>(false);
  const [selectedTheme, setSelectedTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );

  useEffect(() => {
    const selectedTheme = localStorage.getItem("selectedTheme") || "dark";
    setSelectedTheme(selectedTheme);

    selectedTheme === "dark" ? setDarkMode() : setLightMode();
    setCheckBoxVal(selectedTheme === "dark");
  }, []);

  const navigate = useNavigate();
  function navigateToPage(string: any) {
    navigate(string);
  }

  const setDarkMode = () => {
    document.querySelector("body")?.setAttribute("data-theme", "dark");
    localStorage.setItem("selectedTheme", "dark");
    setSelectedTheme(selectedTheme);
  };

  const setLightMode = () => {
    document.querySelector("body")?.setAttribute("data-theme", "light");
    localStorage.setItem("selectedTheme", "light");
    setSelectedTheme(selectedTheme);
  };
  const shouldShowRewardsBtn = () => {
    if (pathname.includes("discover") && pathname.split("/").length > 2) {
      return true;
    }
    if (pathname.includes("vault")) {
      return true;
    }
    return false;
  };
  const toggleTheme = (e: any) => {
    setCheckBoxVal(e.target.checked);
    if (e.target.checked) setDarkMode();
    else setLightMode();
  };

  const items = [
    {
      label: "Dashboard",
      command: () => {
        navigateToPage("/");
      },
    },
    {
      label: "Discover ",
      command: () => {
        navigateToPage("discover");
      },
    },
    {
      label: "Portfolio",
      command: () => {
        navigateToPage("/portfolio");
      },
    },
    {
      label: "Earn",
      className: "",
      target: "_blank",
      items: [
        {
          label: "Telos",
          command: () => {
            navigateToPage("/earn/telos");
          },
        },
        {
          label: "Polygon",
          command: () => {
            navigateToPage("/earn/polygon ");
          },
        },
        {
          label: "Degen L3",
          command: () => {
            navigateToPage("/earn/degen");
          },
        },
      ],
    },
    // {
    //   label: "Bridges",
    //   className: "",
    //   target: "_blank",
    //   items: [
    //     {
    //       label: "Mantle Network",
    //       url: "https://bridge.mantle.xyz/",
    //       target: "_blank",
    //     },
    //     {
    //       label: "Manta Pacific",
    //       url: "https://pacific-bridge.manta.network/",
    //       target: "_blank",
    //     },
    //     {
    //       label: "Symbiosis",
    //       url: "https://app.symbiosis.finance/swap?chainIn=Ethereum&tokenIn=ETH&tokenOut=ETH",
    //       target: "_blank",
    //     },
    //     {
    //       label: "Retrobridge",
    //       url: "https://app.retrobridge.io/?tokenFrom=ETH&destination=Arbitrum+One",
    //       target: "_blank",
    //     },
    //   ],
    // },
  ];

  const start = (
    <>
      <div className="flexStart">
        <HeadlessSidebar />
        <img
          alt="logo"
          src={mobileLogoImg}
          height="48"
          className=" tabRightMar crsr_pntr notMobileDevice"
          onClick={() => {
            navigateToPage("/");
          }}
        />
        <img
          alt="logo"
          src={mobileLogoImg}
          height="50"
          className="logoMarg crsr_pntr mobileDevice"
          onClick={() => {
            navigateToPage("/");
          }}
        ></img>
      </div>
    </>
  );
  const end = (
    <div className="flexTwoBtn">
      <>
        <div className="flexTwoBtn notMobileDevice">
          <Rewards />
          {shouldShowRewardsBtn() && <DexDrop />}
        </div>
      </>
      <ConnectBtn />
    </div>
  );

  const toggleButton = (
    <div className="dark_mode">
      <input
        defaultValue="dark"
        className="dark_mode_input"
        type="checkbox"
        id="darkmode-toggle"
        checked={checkBoxVal}
        onClick={toggleTheme}
      />
      <label className="dark_mode_label" htmlFor="darkmode-toggle">
        <Sun />
        <Moon />
      </label>
    </div>
  );

  const endButton = (
    <>
      <div className="flexTogg ">
        <div className="mr_mob_20">{end}</div>
        <div className="tabRightMar2 toggleButton_mrgn">{toggleButton}</div>
      </div>{" "}
    </>
  );
  return (
    <>
      <div className="bg_gray">
        {/* <Banner /> */}
        <Menubar
          model={items}
          start={start}
          end={endButton}
          className="custom-container"
        />
      </div>
      <div className="main mt-1">
        <Outlet />
      </div>
    </>
  );
}

export default RoutingLayout;
